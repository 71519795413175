import { useState, useCallback, useEffect } from 'react';
import { transcribeAndTranslateFile } from '../api/claraService';
import { saveTranscription } from '../utils/fileUtils';
import { ProcessingStatus, processingState } from '../api/claraService';

export const useFileProcessing = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [processingStatus, setProcessingStatus] = useState({
    status: ProcessingStatus.IDLE,
    message: '',
    progress: 0,
    error: null
  });

  // Subscribe to processing status updates
  useEffect(() => {
    const unsubscribe = processingState.subscribe((status) => {
      setProcessingStatus(status);
    });

    return () => unsubscribe();
  }, []);

  const processFile = useCallback(async (file) => {
    setIsProcessing(true);
    setError(null);
    setCurrentFile(file);
  
    try {
      // Pre-check: Only allow .mp4 files to be processed
      if (!file.name.endsWith(".mp4") && !file.name.endsWith(".mp3")) {
        console.warn(`[processFile] Skipping non-mp4 file: ${file.name}`);
        return; // Exit early if not .mp4
      }
  
      // Pre-calculate the base name
      const baseName = file.name.replace(/\.[^/.]+$/, ""); // Remove file extension
  
      // Filter out previous entries of the same base name with a different extension
      setProcessedFiles((prev) =>
        prev.filter((f) => f.name.endsWith(".mp4") && !f.name.endsWith(".mp3") || !f.name.startsWith(baseName))
      );
  
      // Check if the file has already been processed
      const existingFile = processedFiles.find(
        (f) => f.name === file.name && f.transcription
      );
      if (existingFile) {
        console.log(`[processFile] File already processed: ${file.name}`);
        return existingFile; // Skip further processing
      }
  
      // Process the .mp4 file
      const data = await transcribeAndTranslateFile(file);
  
      // Save transcription
      const jsonFileName = await saveTranscription(file.name, {
        fileName: file.name,
        fileSize: file.size,
        processedAt: new Date().toISOString(),
        transcriptionData: data,
      });
  
      const processedFile = {
        name: file.name,
        jsonFile: jsonFileName,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
        transcription: data,
        lastProcessed: new Date().toISOString(),
      };
  
      // Update the processedFiles state
      setProcessedFiles((prev) => {
        const updatedFiles = prev.filter((f) => f.name !== file.name); // Remove duplicates
        updatedFiles.push(processedFile); // Add the new file
        console.log(`[processFile] Updated processedFiles:`, updatedFiles);
        return updatedFiles;
      });
  
      return processedFile;
    } catch (err) {
      console.error("Error processing file:", err);
      setError(err.message || "Error processing file");
      throw err;
    } finally {
      setIsProcessing(false);
      setCurrentFile(null);
    }
  }, [processedFiles]);
  
  

  const getProcessedFile = useCallback((fileName) => {
    return processedFiles.find(f => f.name === fileName);
  }, [processedFiles]);

  const removeProcessedFile = useCallback((fileName) => {
    setProcessedFiles(prev => prev.filter(f => f.name !== fileName));
  }, []);

  const clearProcessedFiles = useCallback(() => {
    setProcessedFiles([]);
  }, []);

  // Function to check if a file is currently being processed
  const isFileProcessing = useCallback((fileName) => {
    return isProcessing && processedFiles.some(f => f.name === fileName && !f.transcription);
  }, [isProcessing, processedFiles]);

  // Function to get processing status for all files
  const getProcessingStatus = useCallback(() => {
    return {
      totalFiles: processedFiles.length,
      processedFiles: processedFiles.filter(f => f.transcription).length,
      processingFiles: processedFiles.filter(f => !f.transcription).length,
      isProcessing,
      currentFile: currentFile?.name || null,
      ...processingStatus
    };
  }, [processedFiles, isProcessing, currentFile, processingStatus]);

  // Clean up URLs when component unmounts
  useEffect(() => {
    return () => {
      processedFiles.forEach(file => {
        if (file.url) {
          URL.revokeObjectURL(file.url);
        }
      });
    };
  }, [processedFiles]);

  return {
    processFile,
    getProcessedFile,
    removeProcessedFile,
    clearProcessedFiles,
    isFileProcessing,
    getProcessingStatus,
    processedFiles,
    currentFile,
    processingStatus,
    isProcessing,
    error
  };
};