import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FileWizard from './components/FileWizard'; // Replace this with your main page component
import LoginScreen from './components/LoginScreen';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import SessionExpiredModal from './components/SessionExpiredModal';
import { isTokenExpired } from './utils/authFetch'; // Utility function to check token expiration

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('claraJwtToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('claraJwtToken'); // Clear token
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("claraJwtToken");
      if (token && isTokenExpired(token)) {
        setIsSessionExpired(true); // Show the modal
      }
    };

    // Check on app load
    checkToken();

    // Optionally, re-check periodically
    const interval = setInterval(checkToken, 10000); // Check every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const handleRelogin = () => {
    setIsSessionExpired(false);
    localStorage.removeItem("claraJwtToken");
    setIsLoggedIn(false);
    window.location.href = "/login"; // Redirect to login page
  };

  return (
    <>
      <Router>
        <Routes>
          {/* Main Application Page */}
          <Route 
            path="/" 
            element={isLoggedIn ? <FileWizard onLogout={handleLogout} /> : <LoginScreen onLoginSuccess={handleLoginSuccess} />} 
          />
          {/* Terms of Service */}
          <Route path="/tos" element={<TermsOfService />} />
          {/* Privacy Policy */}
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* Redirect unknown routes */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>

      {/* Session Expired Modal */}
      <SessionExpiredModal isOpen={isSessionExpired} onRelogin={handleRelogin} />
    </>
  );
}

export default App;
