// fileSystemManager.js

export const FILE_PATTERNS = {
  // Expanded video format support
  VIDEO: /\.(mp4|webm|ogg|mov|avi|wmv|flv|mkv|m4v|3gp|mpeg|mpg|ts|mts|m2ts)$/i,
  TRANSCRIPT: /_transcript\.json$/i,
  AUDIO: /\.(mp3)$/i
};

export class FileSystemManager {
  constructor() {
    this.videoFiles = new Map(); // Maps base names to video file info
    this.transcriptFiles = new Map(); // Maps base names to transcript file info
  }

  getBaseName(fileName) {
    return fileName
      .replace(/_transcript\.json$/i, '')
      .replace(/\.[^/.]+$/, '');
  }

  addFilePair(video, transcript) {
    const baseName = this.getBaseName(video.name);
    
    // Clean up any existing URL for this video
    const existingVideo = this.videoFiles.get(baseName);
    if (existingVideo?.url?.startsWith('blob:')) {
      URL.revokeObjectURL(existingVideo.url);
    }
    
    // Add video with a fresh blob URL
    this.videoFiles.set(baseName, {
      file: video.file,
      url: URL.createObjectURL(video.file),
      name: video.name,
      size: video.size,
      type: video.type,
      lastModified: video.lastModified
    });
  
    // Add transcript
    if (transcript) {
      this.transcriptFiles.set(baseName, {
        file: transcript.file,
        name: transcript.name,
        size: transcript.size,
        lastModified: transcript.lastModified,
        transcription: transcript.transcription
      });
    }
  
    return baseName;
  }
  
  cleanup() {
    // Clean up all blob URLs
    for (const videoInfo of this.videoFiles.values()) {
      if (videoInfo.url?.startsWith('blob:')) {
        URL.revokeObjectURL(videoInfo.url);
      }
    }
    this.videoFiles.clear();
    this.transcriptFiles.clear();
  }

  async readTranscriptFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        try {
          const rawContent = JSON.parse(e.target.result);
          
          // Map the nested structure to what VideoPlayerComponent expects
          const mappedContent = {
            translation: {
              source_language: rawContent?.transcriptionData?.metadata?.model_info?.['4f82af92-7749-4465-92ad-3db204bc8e03']?.name || 'ES',
              timestamped_sentences: rawContent?.transcriptionData?.translation?.timestamped_sentences || []
            }
          };
  
          // Validate the mapped content
          if (!mappedContent.translation?.timestamped_sentences?.length) {
            console.error('Raw transcript data:', rawContent);
            throw new Error('Missing or invalid timestamped_sentences data');
          }
  
          resolve(mappedContent);
        } catch (error) {
          console.error('Error processing transcript:', error);
          reject(new Error(`Invalid transcript format: ${error.message}`));
        }
      };
      
      reader.onerror = () => reject(new Error('Error reading transcript file'));
      reader.readAsText(file);
    });
  }

  async scanDirectory(fileList) {
    this.videoFiles.clear();
    this.transcriptFiles.clear();
    
    const files = Array.from(fileList);
    console.log('Scanning files:', files.map(f => f.name));
    
    const filesByBase = new Map();
    
    files.forEach(file => {
      const fileName = file.name;
      const baseName = this.getBaseName(fileName);
      
      if (!filesByBase.has(baseName)) {
        filesByBase.set(baseName, { video: null, transcript: null });
      }
      
      const pair = filesByBase.get(baseName);
      
      if (FILE_PATTERNS.VIDEO.test(fileName)) {
        pair.video = {
          file,
          url: URL.createObjectURL(file),
          name: fileName,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified
        };
      } else if (FILE_PATTERNS.TRANSCRIPT.test(fileName)) {
        pair.transcript = {
          file,
          name: fileName,
          size: file.size,
          lastModified: file.lastModified
        };
      }
    });

    const processedPairs = [];
    for (const [baseName, pair] of filesByBase) {
      if (pair.transcript) {
        try {
          const content = await this.readTranscriptFile(pair.transcript.file);
          pair.transcript.transcription = content;
          
          if (pair.video) {
            this.videoFiles.set(baseName, pair.video);
            this.transcriptFiles.set(baseName, pair.transcript);
            
            processedPairs.push({
              baseName,
              video: pair.video,
              transcript: pair.transcript,
              lastModified: Math.max(
                pair.video.lastModified,
                pair.transcript.lastModified
              )
            });
          }
        } catch (error) {
          console.error(`Error reading transcript for ${baseName}:`, error);
        }
      }
    }

    return processedPairs;
  }

  getFilePair(baseName) {
    if (!baseName) {
      console.warn('getFilePair called with no baseName');
      return null;
    }
    
    const video = this.videoFiles.get(baseName);
    const transcript = this.transcriptFiles.get(baseName);
    
    console.log('getFilePair:', {
      baseName,
      foundVideo: Boolean(video),
      foundTranscript: Boolean(transcript),
      videoKeys: Array.from(this.videoFiles.keys()),
      transcriptKeys: Array.from(this.transcriptFiles.keys())
    });

    return { video, transcript };
  }

  hasCompletePair(baseName) {
    const video = this.videoFiles.get(baseName);
    const transcript = this.transcriptFiles.get(baseName);
    return Boolean(video && transcript?.transcription);
  }

}

export const fileSystemManager = new FileSystemManager();