import React, { useState, useEffect, useRef } from 'react';
import { BookmarkIcon, ArrowLeft, Copy, Check, Loader, AlertCircle, MessageSquare, Scale } from 'lucide-react';

const AIToolsTeaser = () => (
  <div className="flex flex-col items-center justify-center flex-1 text-center space-y-6">
    <br></br>
    <h2 className="text-2xl font-bold text-[#2d3b31]">AI Tools - Coming Soon!</h2>
    <div className="flex flex-col space-y-4">
      <button className="flex items-center px-6 py-3 bg-[#b3c2b7] text-[#2d3b31] rounded-lg shadow hover:bg-[#d1dbd3] transition">
        <Scale className="w-5 h-5 mr-2" />
        Cross Reference Statutes
      </button>
      <button className="flex items-center px-6 py-3 bg-[#b3c2b7] text-[#2d3b31] rounded-lg shadow hover:bg-[#d1dbd3] transition">
        <MessageSquare className="w-5 h-5 mr-2" />
        Chat with Transcript
      </button>
    </div>
  </div>
);

const extractTranscriptData = (transcription) => {
  if (!transcription) {
    throw new Error('No transcription data provided');
  }

  // Try to extract from the new format with transcriptionData.translation
  if (transcription.transcriptionData?.translation?.timestamped_sentences) {
    return {
      source_language: transcription.transcriptionData.metadata?.source_language || 'ES',
      timestamped_sentences: transcription.transcriptionData.translation.timestamped_sentences
    };
  }

  // Try to extract from the mapped format (already processed)
  if (transcription.translation?.timestamped_sentences) {
    return transcription.translation;
  }

  // If we have raw results, map them from the ASR format
  if (transcription.transcriptionData?.results) {
    const results = transcription.transcriptionData.results;
    return {
      source_language: 'ES',
      timestamped_sentences: results.channels[0].alternatives[0].words.map((word, i, arr) => ({
        translation: word.punctuated_word,
        start: word.start,
        end: word.end,
        original: word.punctuated_word,
        confidence: {
          transcription: word.confidence,
          overall: word.confidence,
          translation_match: 1
        }
      }))
    };
  }

  console.error('Unsupported transcription format:', transcription);
  throw new Error('Unsupported transcription data format');
};

const VideoPlayerComponent = ({ videoUrl, transcription, onClose }) => {
  const [activeTab, setActiveTab] = useState('english');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [currentSegment, setCurrentSegment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const videoRef = useRef(null);

  const tabs = [
    { id: 'english', label: 'English Transcript' },
    { id: 'spanish', label: 'Spanish Transcript' },
    { id: 'tools', label: 'AI Tools' },
    // { id: 'bookmarks', label: 'Bookmarks & Notes' },
  ];

  useEffect(() => {
    try {
      setIsLoading(true);
      if (transcription) {
        const data = extractTranscriptData(transcription);
        setTranscriptionData(data);
        setError(null);
      }
    } catch (err) {
      setError({
        message: "Failed to process transcription data",
        details: err.message,
        technicalDetails: {
          receivedStructure: transcription ? Object.keys(transcription) : [],
          error: err.toString(),
          timestamp: new Date().toISOString()
        }
      });
    } finally {
      setIsLoading(false);
    }
  }, [transcription]);

  useEffect(() => {
    console.log('Video URL received:', videoUrl);
    setVideoError(null);  // Clear any previous errors
    
    return () => {
      console.log('Cleaning up video component, URL was:', videoUrl);
    };
  }, [videoUrl]);

  // Handle video time updates
  useEffect(() => {
    const video = videoRef.current;
    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
      
      // Find current segment
      const entries = getTranscriptEntries();
      const current = entries.find(entry => 
        currentTime >= entry.start && currentTime < entry.end
      );
      if (current) {
        setCurrentSegment(current);
      }
    };

    video?.addEventListener('timeupdate', handleTimeUpdate);
    return () => video?.removeEventListener('timeupdate', handleTimeUpdate);
  }, [currentTime, transcriptionData]);

  const handleVideoError = (e) => {
    const error = e.target.error;
    console.error('Video error:', error);
    setVideoError(error);
    
    // Try to recover by reloading the video
    if (videoRef.current) {
      videoRef.current.load();
    }
  };

  const formatTime = (seconds) => {
    const pad = (num) => num.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 100);

    return `${hours > 0 ? hours + ':' : ''}${pad(minutes)}:${pad(secs)}.${pad(ms)}`;
  };

  const getTranscriptEntries = () => {
    if (!transcriptionData?.timestamped_sentences) {
      return [];
    }

    return transcriptionData.timestamped_sentences.map(entry => ({
      text: activeTab === 'english' ? entry.translation : entry.original,
      timestamp: `${formatTime(entry.start)}-${formatTime(entry.end)}`,
      start: entry.start,
      end: entry.end,
      confidence: activeTab === 'english' ? entry.confidence.overall : entry.confidence.transcription
    }));
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredEntries = getTranscriptEntries().filter(entry =>
    entry.text.toLowerCase().includes(searchQuery)
  );

  const handleCopy = async (text, index) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleSegmentClick = (entry) => {
    if (videoRef.current) {
      videoRef.current.currentTime = entry.start;
      videoRef.current.play();
    }
  };

  const isCurrentSegment = (start, end) => {
    return currentTime >= start && currentTime < end;
  };

  const ErrorDisplay = ({ error }) => (
    <div className="p-6 bg-red-50 rounded-lg">
      <div className="flex items-start space-x-3">
        <AlertCircle className="w-6 h-6 text-red-500 mt-1" />
        <div className="flex-1">
          <h3 className="text-lg font-medium text-red-800 mb-2">
            {error.message}
          </h3>
          <p className="text-red-700 mb-4">
            {error.details}
          </p>
          <div className="bg-red-100 p-4 rounded-md">
            <p className="text-sm font-medium text-red-800 mb-2">Technical Details (for Support):</p>
            <pre className="text-xs text-red-700 whitespace-pre-wrap break-all">
              {JSON.stringify(error.technicalDetails, null, 2)}
            </pre>
          </div>
          <div className="mt-4 flex gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
            >
              Go Back
            </button>
            <button
              onClick={() => {
                const errorReport = {
                  ...error,
                  url: window.location.href,
                  userAgent: navigator.userAgent,
                  timestamp: new Date().toISOString()
                };
                const blob = new Blob([JSON.stringify(errorReport, null, 2)], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `error-report-${new Date().toISOString()}.json`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
              }}
              className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors"
            >
              Download Error Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (error) {
    return (
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center p-4 border-b">
          <button 
            onClick={onClose}
            className="flex items-center text-gray-600 hover:text-gray-800"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Files
          </button>
        </div>
        <div className="flex-1 p-6">
          <ErrorDisplay error={error} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      {/* Back Button Header */}
      <div className="flex items-center p-4 border-b">
        <button 
          onClick={onClose}
          className="flex items-center text-gray-600 hover:text-gray-800"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Files
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Video Player */}
        <div className="aspect-video bg-black shadow-md rounded-lg relative">
        {videoError ? (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 text-white p-4 rounded-lg">
            <div className="text-center">
              <AlertCircle className="w-8 h-8 mx-auto mb-2" />
              <p>Error loading video: {videoError.message}</p>
              <button 
                onClick={() => {
                  setVideoError(null);
                  if (videoRef.current) {
                    videoRef.current.load();
                  }
                }}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Retry
              </button>
            </div>
          </div>
        ) : null}
        <video 
          ref={videoRef}
          controls 
          className="w-full h-full rounded-lg shadow-md"
          src={videoUrl}
          onError={handleVideoError}
        >
          Your browser does not support the video tag.
        </video>
      </div>

        {/* Transcript Section */}
        <div className="flex-1 flex flex-col min-h-0">
          {/* Tabs */}
          <div className="border-b">
            <div className="flex">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`px-6 py-3 font-medium ${
                    activeTab === tab.id 
                      ? 'text-gray-800 bg-[#d1dbd3]' 
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                  disabled={isLoading}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>
          {activeTab !== 'tools' && (
            isLoading ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="flex flex-col items-center space-y-4">
                  <Loader className="w-8 h-8 animate-spin text-[#d1dbd3]" />
                  <p className="text-gray-600">Loading transcript...</p>
                </div>
              </div>
            ) : (
              <>
                {/* Current Segment Display */}
                {currentSegment && (
                  <div className="p-4 bg-gray-100 border-b">
                    <div className="max-w-3xl mx-auto">
                      <h3 className="text-sm font-medium text-gray-500 mb-2">Currently Playing:</h3>
                      <p className="text-lg text-gray-900">{currentSegment.text}</p>
                      <p className="text-sm text-gray-500 mt-1">{currentSegment.timestamp}</p>
                    </div>
                  </div>
                )}
  
                {/* Search */}
                <div className="p-4 bg-gray-50">
                  <input
                    type="search"
                    placeholder="Search transcript"
                    className="w-full px-4 py-2 border rounded-lg"
                    onChange={handleSearch}
                    value={searchQuery}
                  />
                </div>
  
                {/* Transcript Content */}
                <div className="flex-1 overflow-y-auto p-4">
                  {filteredEntries.map((entry, index) => (
                    <div 
                      key={index} 
                      className={`mb-6 group p-2 rounded transition-colors duration-200 cursor-pointer ${
                        isCurrentSegment(entry.start, entry.end) 
                          ? 'bg-[#d1dbd3] text-white' 
                          : 'hover:bg-gray-50'
                      }`}
                      onClick={() => handleSegmentClick(entry)}
                    >
                      <div className="flex justify-between items-start mb-1">
                        <p className={`flex-1 ${
                          isCurrentSegment(entry.start, entry.end) 
                            ? 'text-gray-800' 
                            : 'text-gray-600'
                        }`}>
                          {entry.text}
                        </p>
                        <div className="flex items-center space-x-2">
                          <button 
                            className={`flex items-center gap-1 ${
                              isCurrentSegment(entry.start, entry.end)
                                ? 'text-gray-800 hover:text-gray-900'
                                : 'text-gray-400 hover:text-gray-600'
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(entry.text, index);
                            }}
                          >
                            {copiedIndex === index ? (
                              <>
                                <Check size={16} className="text-green-500" />
                                <span className="text-green-500">Copied!</span>
                              </>
                            ) : (
                              <>
                                <Copy size={16} />
                                <span>Copy</span>
                              </>
                            )}
                          </button>
                          <button 
                            className={`${
                              isCurrentSegment(entry.start, entry.end)
                                ? 'text-gray-800 hover:text-gray-900'
                                : 'text-gray-400 hover:text-gray-600'
                            }`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <BookmarkIcon size={16} />
                          </button>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <span 
                          className={`text-sm ${
                            isCurrentSegment(entry.start, entry.end)
                              ? 'text-gray-800'
                              : 'text-gray-400'
                          }`}
                        >
                          {entry.timestamp}
                        </span>
                        <span className={`text-sm ${
                          isCurrentSegment(entry.start, entry.end)
                            ? 'text-gray-800'
                            : 'text-gray-400'
                        }`}>
                          Confidence: {Math.round(entry.confidence * 100)}%
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )
            )}
          
          {activeTab === 'tools' && <AIToolsTeaser />}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerComponent;