import React from 'react';
import { FileIcon } from 'lucide-react';
import { FileItem } from './WizardComponents';
import {ProcessingStatus} from '../api/claraService'
import { exportTranscription } from '../utils/fileUtils';
import { useMemo } from 'react';
import { FILE_PATTERNS } from '../utils/fileSystemUtils';
import { getSupportedFormatsString } from '../utils/videoUtils';

const SelectFolderStep = ({ onSelect }) => {
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const mediaFiles = files.filter(file => 
      (FILE_PATTERNS.VIDEO.test(file.name) || FILE_PATTERNS.AUDIO.test(file.name)) && 
      (file.type.startsWith('video/') || file.type.startsWith('audio/mpeg'))
    );
    
    if (mediaFiles.length === 0) {
      alert(`No supported video files found in the selected folder. Please check your files and try again.`);
      return;
    }

    onSelect(event);
  };

  return (
    <div className="max-w-xl mx-auto bg-gray-50 rounded-xl shadow-sm p-8">
      <h3 className="text-2xl font-medium text-gray-800 mb-4">Step 1: Select Files Folder</h3>
      <p className="text-gray-600 mb-8">
        Select the video file you'd like to transcribe & translate.
      </p>
      <input
        type="file"
        multiple
        accept="video/*,.mp4,.avi,.mov,audio/mpeg,.mp3"
        onChange={handleFileSelect}
        className="hidden"
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className="px-6 py-2.5 bg-[#d1dbd3] text-gray-700 rounded-lg hover:bg-[#b3c2b7] transition-colors duration-200 cursor-pointer inline-block font-medium shadow-sm"
      >
        Import Footage
      </label>
    </div>
  );
};

const ConfirmStep = ({ files, onConfirm, onCancel }) => (
  <div className="max-w-xl mx-auto">
    <h3 className="text-2xl font-medium text-gray-800 mb-4">Step 2: Confirm File List</h3>
    <div className="border rounded-lg mb-6">
      {files.map((file, index) => (
        <FileItem
          key={index}
          name={file.name}
          size={file.size}
          showReview={true}
          isConfirmStep={true}
        />
      ))}
    </div>
    <div className="flex justify-end space-x-4">
      <button
        onClick={onCancel}
        className="px-6 py-2.5 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
      >
        Cancel
      </button>
      <button
        onClick={onConfirm}
        className="px-6 py-2.5 bg-[#d1dbd3] text-gray-700 rounded-lg hover:bg-[#b3c2b7] transition-colors duration-200"
      >
        Start Transcribe & Translate
      </button>
    </div>
  </div>
);

const ProcessingStep = ({ files, processingStatus = { 
  status: ProcessingStatus.IDLE, 
  message: 'Waiting to start...',
  progress: 0,
  error: null 
} }) => {
  const getStatusDisplay = () => {
    // Ensure we have a valid status, default to IDLE if not
    const currentStatus = processingStatus?.status || ProcessingStatus.IDLE;
    
    switch (currentStatus) {
      case ProcessingStatus.UPLOADING:
        return {
          icon: '📤',
          color: 'text-blue-500',
          progressColor: 'bg-blue-500'
        };
      case ProcessingStatus.TRANSCRIBING:
        return {
          icon: '🎙️',
          color: 'text-purple-500',
          progressColor: 'bg-purple-500'
        };
      case ProcessingStatus.TRANSLATING:
        return {
          icon: '🔄',
          color: 'text-green-500',
          progressColor: 'bg-green-500'
        };
      case ProcessingStatus.PROCESSING:
        return {
          icon: '⚙️',
          color: 'text-yellow-500',
          progressColor: 'bg-yellow-500'
        };
      case ProcessingStatus.COMPLETE:
        return {
          icon: '✅',
          color: 'text-green-500',
          progressColor: 'bg-green-500'
        };
      case ProcessingStatus.ERROR:
        return {
          icon: '❌',
          color: 'text-red-500',
          progressColor: 'bg-red-500'
        };
      default:
        return {
          icon: '⏳',
          color: 'text-gray-500',
          progressColor: 'bg-gray-500'
        };
    }
  };

  const statusStyle = getStatusDisplay();

  return (
    <div className="max-w-xl mx-auto">
      <h3 className="text-2xl font-medium text-gray-800 mb-6">Processing Files</h3>
      
      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        {files.map((file, index) => (
          <div key={index} className="border-b last:border-b-0 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <span className="text-2xl">{statusStyle.icon}</span>
                <div>
                  <div className="font-medium text-gray-700">{file.name}</div>
                  <div className={`text-sm ${statusStyle.color}`}>
                    {processingStatus?.message || 'Waiting to start...'}
                  </div>
                </div>
              </div>
              
              {processingStatus?.status !== ProcessingStatus.ERROR && 
               processingStatus?.status !== ProcessingStatus.COMPLETE && (
                <div className="w-32 bg-gray-200 rounded-full h-2">
                  <div 
                    className={`${statusStyle.progressColor} h-2 rounded-full transition-all duration-300`}
                    style={{ width: `${processingStatus?.progress || 0}%` }}
                  />
                </div>
              )}
            </div>

            {processingStatus?.error && (
              <div className="mt-2 text-sm text-red-500">
                Error: {processingStatus.error.message}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-4 p-4 bg-gray-50 rounded-lg">
        <div className="text-sm text-gray-600">
          Current Status: {processingStatus?.status || ProcessingStatus.IDLE}
        </div>
        {processingStatus?.message && (
          <div className="text-sm text-gray-600 mt-1">
            {processingStatus.message}
          </div>
        )}
      </div>
    </div>
  );
};

const NavigatingStep = ({ files, onReview, onAddMore }) => {
  const handleExport = async (file) => {
    console.log('Exporting transcription for:', file.name);
    try {
      const exportedFile = await exportTranscription(file.name);
      console.log('Successfully exported to:', exportedFile);
    } catch (error) {
      console.error('Error exporting file:', error);
    }
  };

  return (
    <div className="max-w-xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-2xl font-medium text-gray-800">My Files</h3>
        <button
          onClick={onAddMore}
          className="px-4 py-2 bg-[#d1dbd3] text-gray-700 rounded-lg hover:bg-[#b3c2b7] transition-colors duration-200 font-medium shadow-sm"
        >
          Add More Files
        </button>
      </div>
      
      <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-6">
        {files.map((file, index) => (
          <FileItem 
            key={index}
            name={file.name}
            size={file.size}
            showReview={true}
            onReview={() => onReview(file)}
            // onExport={() => handleExport(file)}
            lastProcessed={file.lastProcessed}
          />
        ))}
      </div>
    </div>
  );
};



export { SelectFolderStep, ConfirmStep, ProcessingStep, NavigatingStep };