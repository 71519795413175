import React from 'react';

const TermsOfService = () => (
  <main className="p-6 max-w-4xl mx-auto">
    <section>
      <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
      <p className="mb-4">
        <strong>Effective Date:</strong> 12/5/2024
      </p>

      <p className="mb-6">
        Welcome to Clara AI, created by CASCDR Inc—a service designed to transcribe and translate bodycam footage with the goal of ensuring accuracy and transparency in the pursuit of fair trials. By accessing or using this service, you agree to the following Terms of Service. Please read them carefully.
      </p>

      <h2 className="text-2xl font-semibold mb-3">1. Service Overview</h2>
      <p className="mb-6">
        Clara AI employs state-of-the-art technology to deliver accurate transcription and translation of bodycam footage. While we strive to provide the highest level of accuracy, no technology is infallible. Users are advised to double-check all outputs to ensure correctness. This service is licensed exclusively to Travis County, Texas, under the terms outlined in a separate agreement.
      </p>

      <h2 className="text-2xl font-semibold mb-3">2. Disclaimer of Liability</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>Clara AI and its operators shall not be held liable for any inaccuracies, errors, or omissions in the transcriptions or translations provided.</li>
        <li>The service is provided on an "as-is" and "as-available" basis, and we expressly disclaim any warranties, including but not limited to accuracy, reliability, and fitness for a particular purpose.</li>
        <li>Users acknowledge that transcription and translation outputs must be verified independently for use in any legal, administrative, or official capacity.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">3. Security and Privacy</h2>
      <p className="mb-4">
        Clara AI takes precautions to ensure that all sensitive data remains in the client and is not stored at rest within our systems.
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li>We adhere to standard security practices and employ third-party services that are SOC2-compliant to safeguard your data.</li>
        <li>Despite our efforts to harden security, we cannot guarantee that unauthorized access or data leaks will never occur.</li>
        <li>Users are responsible for notifying us immediately if they suspect a security breach involving their account or data.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">4. Third-Party Services</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>This service may integrate or utilize third-party tools and platforms that meet SOC2 compliance standards. However, we cannot be held liable for the security or actions of these third-party providers.</li>
        <li>Any third-party services used in conjunction with this application are subject to their own terms and privacy policies.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">5. License and Revocation</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>The service is provided under a private license agreement with Travis County, Texas. Use of the service is contingent upon compliance with this agreement.</li>
        <li>We reserve the right to revoke access to the service at any time, particularly in cases of non-payment, failure to comply with guidelines, or misuse of the platform.</li>
        <li>Revocation of service does not absolve users from outstanding payment obligations.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">6. Acceptable Use Policy</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>Users agree to use the service only for lawful purposes and in accordance with these Terms of Service.</li>
        <li>Misuse of the service, including but not limited to tampering with outputs, unauthorized access, or attempts to compromise the system, will result in immediate termination of access.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">7. Limitation of Liability</h2>
      <p className="mb-4">To the fullest extent permitted by law:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>Clara AI and its affiliates, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to the use or inability to use the service.</li>
        <li>In no event shall our total liability to you exceed the fees paid by you for the service in the 12 months preceding the claim.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">8. Governing Law</h2>
      <p className="mb-6">
        These Terms of Service are governed by and construed in accordance with the laws of the State of Texas. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts located in Travis County, Texas.
      </p>

      <h2 className="text-2xl font-semibold mb-3">9. Modifications to Terms</h2>
      <p className="mb-6">
        We reserve the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting. Continued use of the service constitutes acceptance of the revised terms.
      </p>

      <h2 className="text-2xl font-semibold mb-3">10. Termination</h2>
      <p className="mb-6">
        We may terminate or suspend your access to the service at our discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties.
      </p>

      <h2 className="text-2xl font-semibold mb-3">11. Contact Information</h2>
      <p className="mb-6">
        If you have any questions about these Terms of Service, please contact us at:
      </p>
      <p className="mb-6 font-semibold">luke@cascdr.xyz</p>

      <p className="mb-6">
        By using this service, you acknowledge that you have read, understood, and agree to these Terms of Service.
      </p>
    </section>
  </main>
);

export default TermsOfService;
