import { 
  Document, 
  Paragraph, 
  Table, 
  TableRow, 
  TableCell, 
  TextRun, 
  AlignmentType, 
  BorderStyle,
  Packer,
  ImageRun
} from 'docx';
import { fileSystemManager } from './fileSystemUtils';
import { saveAs } from 'file-saver';
import LZString from 'lz-string';



export const STORAGE_KEY = 'clara_processed_files';

const loadClaraLogo = async () => {
  try {
    const response = await fetch('/clara-icon.png');
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    return arrayBuffer;
  } catch (error) {
    console.error('Error loading Clara logo:', error);
    return null;
  }
};

export const getStoredFiles = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
};

// In a new file like indexedDBStorage.js
export const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('ClaraDB', 1);

    request.onerror = () => reject(request.error);
    
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('transcriptions')) {
        db.createObjectStore('transcriptions', { keyPath: 'fileName' });
      }
    };

    request.onsuccess = () => resolve(request.result);
  });
};

export const saveTranscriptionToDB = async (fileName, compressedData) => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['transcriptions'], 'readwrite');
    const store = transaction.objectStore('transcriptions');

    const request = store.put({
      fileName,
      data: compressedData,
      timestamp: new Date().toISOString(),
    });

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};


export const getTranscriptionFromDB = async (fileName) => {
  const db = await initDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['transcriptions'], 'readonly');
    const store = transaction.objectStore('transcriptions');
    const request = store.get(fileName);

    request.onsuccess = () => resolve(request.result?.data || null);
    request.onerror = () => reject(request.error);
  });
};


// Modified storage handler that tries localStorage first, falls back to IndexedDB
export const saveTranscription = async (fileName, data) => {
  try {
    // Block saving non-mp4 files
    if (!fileName.endsWith(".mp4") && !fileName.endsWith(".mp3")) {
      console.warn(`[saveTranscription] Skipping non-mp4 file: ${fileName}`);
      return null; // Exit early for non-mp4 files
    }

    // Compress and save data to localStorage
    const compressedData = LZString.compress(JSON.stringify(data));
    localStorage.setItem(`transcription_${fileName}`, compressedData);

    // Save reference in localStorage metadata
    const fileRecord = {
      originalFile: fileName,
      jsonFile: `${fileName}_transcript.json`,
      processedAt: new Date().toISOString(),
      baseName: fileName.replace(/\.[^/.]+$/, ""),
      storageType: "localStorage",
    };

    const existingRecords = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
    const updatedRecords = existingRecords.filter(
      (record) => record.originalFile !== fileName
    );
    updatedRecords.push(fileRecord);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedRecords));

    // Save transcription data as a JSON file for filesystem storage
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: "application/json" });
    saveAs(blob, `${fileName}_transcript.json`);

    return fileRecord.jsonFile;
  } catch (error) {
    console.error("[saveTranscription] Error:", error);
    throw error;
  }
};





export const getTranscriptionData = async (fileName) => {
  // Try localStorage first with full filename (how it was saved)
  const compressedData = localStorage.getItem(`transcription_${fileName}`);
  if (compressedData) {
    try {
      const decompressedData = LZString.decompress(compressedData);
      return JSON.parse(decompressedData);
    } catch (error) {
      console.error(`Error decompressing data for ${fileName} in localStorage`, error);
    }
  }

  // Try with baseName as fallback for backwards compatibility
  const baseName = fileName.replace(/\.[^/.]+$/, "");
  const compressedDataBaseName = localStorage.getItem(`transcription_${baseName}`);
  if (compressedDataBaseName) {
    try {
      const decompressedData = LZString.decompress(compressedDataBaseName);
      return JSON.parse(decompressedData);
    } catch (error) {
      console.error(`Error decompressing data for ${fileName} in localStorage`, error);
    }
  }

  // IndexedDB fallback - use full filename here too
  const records = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
  const record = records.find(r => r.originalFile === fileName);

  if (record?.storageType === 'indexedDB') {
    const compressedDataFromDB = await getTranscriptionFromDB(fileName);
    if (compressedDataFromDB) {
      try {
        const decompressedData = LZString.decompress(compressedDataFromDB);
        return JSON.parse(decompressedData);
      } catch (error) {
        console.error(`Error decompressing data for ${fileName} in IndexedDB`, error);
      }
    }
  }

  return null;
};


export const cleanUpLocalStorage = () => {
  const records = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
  
  // Filter records that are less than 30 days old
  const cleanedRecords = records.filter(record => {
    const ageInDays = (new Date() - new Date(record.processedAt)) / (1000 * 60 * 60 * 24);
    return ageInDays < 30; // Keep only records from the last 30 days
  });

  // Identify outdated records to remove
  const outdatedRecords = records.filter(record => !cleanedRecords.includes(record));
  outdatedRecords.forEach(record => {
    localStorage.removeItem(`transcription_${record.baseName}`);
  });

  // Update the metadata storage with cleaned records
  localStorage.setItem(STORAGE_KEY, JSON.stringify(cleanedRecords));
};




const createTranscriptionDoc = async (transcriptionData, fileName) => {
  // Handle both old and new data formats
  const translationData = transcriptionData.transcriptionData?.translation || 
                         transcriptionData.translation;
  
  if (!translationData?.timestamped_sentences) {
    throw new Error('Invalid transcript data structure');
  }

  const logoData = await loadClaraLogo();
  
  const doc = new Document({
    sections: [
      {
        properties: {
          page: { pageNumbers: { start: 1, formatType: 'decimal' } }
        },
        children: [
          new Paragraph({
            children: [
              new ImageRun({
                data: logoData,
                transformation: { width: 400, height: 400 },
                alignment: AlignmentType.CENTER
              }),
            ],
            alignment: AlignmentType.CENTER,
            spacing: { before: 1000, after: 400 }
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Clara AI",
                size: 72,
                bold: true,
              })
            ],
            alignment: AlignmentType.CENTER,
            spacing: { after: 400 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Creating Transparency in Pursuit of Justice for All",
                size: 36,
                bold: true,
                color: "666666"
              })
            ],
            alignment: AlignmentType.CENTER,
            spacing: { after: 800 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Video: ${fileName}`,
                size: 28,
              })
            ],
            alignment: AlignmentType.CENTER,
            spacing: { after: 400 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Transcribed: ${new Date(transcriptionData.processedAt).toLocaleString()}`,
                size: 28,
              })
            ],
            alignment: AlignmentType.CENTER,
            spacing: { after: 400 },
          }),
          new Paragraph({
            text: "",
            pageBreakBefore: true
          })
        ]
      },
      {
        properties: {
          page: { 
            pageNumbers: {
              start: 2,
              formatType: 'decimal',
              position: 'bottom-center'
            }
          }
        },
        children: [
          new Table({
            width: { size: 100, type: 'pct' },
            borders: {
              top: { style: BorderStyle.SINGLE, size: 1 },
              bottom: { style: BorderStyle.SINGLE, size: 1 },
              left: { style: BorderStyle.SINGLE, size: 1 },
              right: { style: BorderStyle.SINGLE, size: 1 },
              insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
              insideVertical: { style: BorderStyle.SINGLE, size: 1 }
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: { size: 10, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Language",
                            size: 24,
                            bold: true
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 35, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Original Text",
                            size: 24,
                            bold: true
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 35, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Translation",
                            size: 24,
                            bold: true
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 10, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Start",
                            size: 24,
                            bold: true
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 10, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "End",
                            size: 24,
                            bold: true
                          })
                        ]
                      })
                    ]
                  })
                ]
              }),
              ...translationData.timestamped_sentences.map(sentence => new TableRow({
                children: [
                  new TableCell({
                    width: { size: 10, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: translationData.source_language,
                            size: 20
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 35, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: sentence.original,
                            size: 20
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 35, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: sentence.translation,
                            size: 20
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 10, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: formatTimestamp(sentence.start),
                            size: 20
                          })
                        ]
                      })
                    ]
                  }),
                  new TableCell({
                    width: { size: 10, type: 'pct' },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: formatTimestamp(sentence.end),
                            size: 20
                          })
                        ]
                      })
                    ]
                  })
                ]
              }))
            ]
          })
        ]
      }
    ]
  });

  return doc;
};


const formatTimestamp = (seconds) => {
  const pad = (num) => num.toString().padStart(2, '0');
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  const ms = Math.floor((seconds % 1) * 1000);
  
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`;
};

export const exportTranscription = async (fileName) => {
  try {
    const transcriptionData = await getTranscriptionData(fileName); // Ensure await is used
    if (!transcriptionData) {
      throw new Error('No transcription data found for this file');
    }

    // Validate the transcriptionData structure
    if (
      !transcriptionData.transcriptionData?.translation?.timestamped_sentences &&
      !transcriptionData.translation?.timestamped_sentences
    ) {
      throw new Error('Invalid transcript data structure');
    }

    const baseName = fileName.replace(/\.[^/.]+$/, "");
    const doc = await createTranscriptionDoc(transcriptionData, fileName);
    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${baseName}_transcript.docx`);
    
    return `${baseName}_transcript.docx`;
  } catch (error) {
    console.error('Error creating document:', error);
    throw error;
  }
};
