import React, { useState, useEffect } from 'react';
import { WizardLayout, WelcomeStep, FileItem } from './WizardComponents';
import { SelectFolderStep, ConfirmStep, ProcessingStep } from './WizardSteps';
import VideoPlayerComponent from './VideoPlayerComponent';
import { useFileProcessing } from '../hooks/useFileProcessing';
import { checkServerStatus, API_CONFIG } from '../api/claraService';
import { fileSystemManager } from '../utils/fileSystemUtils';
import { FolderOpen, AlertCircle, Plus } from 'lucide-react';
import { exportTranscription, STORAGE_KEY, saveTranscription} from '../utils/fileUtils';

export default function FileWizard({ onLogout, setCurrentPage }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [syncedFiles, setSyncedFiles] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [serverStatus, setServerStatus] = useState({ checked: false, isRunning: false });
  const [syncError, setSyncError] = useState(null);
  
  const { 
    processFile, 
    getProcessedFile,
    processedFiles,
    isProcessing,
    error,
    processingStatus
  } = useFileProcessing();

  useEffect(() => {
    const checkServer = async () => {
      try {
        const isRunning = await checkServerStatus();
        setServerStatus({ checked: true, isRunning });
      } catch (error) {
        console.warn('Server check failed:', error);
        setServerStatus({ checked: true, isRunning: false });
      }
    };

    checkServer();

    // If we have synced files, go straight to the navigation step
    if (syncedFiles.length > 0) {
      setCurrentStep(5);
    }

    return () => {
      fileSystemManager.cleanup();
    };
  }, [syncedFiles]);

  const handleSyncFolder = async (event) => {
    if (event.target.files) {
      try {
        setSyncError(null);
        const matchedFiles = await fileSystemManager.scanDirectory(event.target.files);
  
        console.log('Matched Files:', matchedFiles); // Debugging log
  
        setSyncedFiles((prevFiles) => {
          const existingFilesMap = new Map(prevFiles.map(file => [file.baseName, file]));
        
          matchedFiles.forEach(file => {
            if (file.baseName && file.video) {
              existingFilesMap.set(file.baseName, file);
            }
          });
        
          return Array.from(existingFilesMap.values());
        });
        
  
        setCurrentStep(5); // Proceed to file list display
      } catch (error) {
        console.error('Error syncing files:', error);
        setSyncError(error.message || 'Error syncing folder');
      }
    }
  };
  

  const handleExport = async (baseName) => {
    try {
      const filePair = fileSystemManager.getFilePair(baseName);
  
      // Fallback to syncedFiles if not in fileSystemManager
      if (!filePair?.transcript?.transcription) {
        const syncedFile = syncedFiles.find(f => f.baseName === baseName);
        if (syncedFile?.transcript?.transcription) {
          fileSystemManager.addFilePair(syncedFile.video, syncedFile.transcript);
        } else {
          throw new Error('No transcript data available for export');
        }
      }
  
      const fileName = `${baseName}.mp4`;
      await exportTranscription(fileName);
    } catch (error) {
      console.error('Export failed:', error);
      alert('Failed to export transcript: ' + error.message);
    }
  };
  

  const getDisplayTitle = () => {
    if (currentVideo) {
      return currentVideo.name;
    }
    return ''//getStepTitle(currentStep);
  };

  const getStepTitle = (step) => {
    const titles = {
      1: 'Welcome to Clara AI',
      2: 'Select Files',
      3: 'Confirm Files',
      4: 'Processing',
      5: 'Your Files'
    };
    return titles[step] || '';
  };

  const handleFileSelect = (event) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files).filter(file => 
        file.type.startsWith('video/') || file.type.startsWith('audio/mpeg')
      );
      
      const files = fileList.map(file => ({
        name: file.name,
        size: `${Math.round(file.size / 1024 / 1024)}MB`,
        url: URL.createObjectURL(file),
        file: file,
        type: file.type
      }));
      
      setSelectedFiles(files);
      setCurrentStep(3);
    }
  };

 const handleConfirm = async () => {
  const isServerRunning = await checkServerStatus();

  if (!isServerRunning) {
    const proceed = window.confirm(
      `Warning: Cannot connect to server at ${API_CONFIG.BASE_URL}. ` +
        'Would you like to proceed anyway? (Files will be stored locally but not processed)'
    );

    if (!proceed) return;
  }

  setCurrentStep(4);
  try {
    const processedResults = await Promise.all(
      selectedFiles.map(async (fileInfo) => {
        try {
          const apiResult = await processFile(fileInfo.file);
          return {
            ...apiResult,
            originalFile: fileInfo.file,
          };
        } catch (error) {
          console.error('Error processing file:', fileInfo.name, error);
          return {
            ...fileInfo,
            error: error.message,
            transcription: null,
          };
        }
      })
    );

    const hasErrors = processedResults.some((file) => file.error);
    if (hasErrors) {
      const retry = window.confirm('Some files failed to process. Would you like to retry?');
      if (retry) {
        return handleConfirm();
      }
    }

    // Save transcription data
    processedResults.forEach(async (file) => {
      if (file.transcription) {
        const transcriptContent = {
          fileName: file.originalFile.name,
          fileSize: file.originalFile.size,
          processedAt: new Date().toISOString(),
          transcriptionData: file.transcription,
        };

        const baseName = file.originalFile.name.replace(/\.[^/.]+$/, '');
        try {
          await saveTranscription(baseName, transcriptContent);
        } catch (error) {
          console.error('Error saving transcription:', error);
        }
      }
    });

    // Update syncedFiles state
    const newFiles = processedResults.map((file) => {
      if (!file.originalFile) {
        console.error('Missing original file reference:', file);
        return null;
      }

      const baseName = file.originalFile.name.replace(/\.[^/.]+$/, '');
      const transcriptFileName = `${baseName}_transcript.json`;

      return {
        baseName,
        video: {
          file: file.originalFile,
          url: URL.createObjectURL(file.originalFile),
          name: file.originalFile.name,
          size: file.originalFile.size,
          type: file.originalFile.type,
          lastModified: file.originalFile.lastModified,
        },
        transcript: file.transcription
          ? {
              name: transcriptFileName,
              transcription: file.transcription,
              lastModified: new Date().getTime(),
            }
          : null,
      };
    }).filter(Boolean);

    setSyncedFiles((prevFiles) => {
      const existingFilesMap = new Map(prevFiles.map((file) => [file.baseName, file]));

      newFiles.forEach((newFile) => {
        existingFilesMap.set(newFile.baseName, newFile);
      });

      return Array.from(existingFilesMap.values());
    });

    setCurrentStep(5);
  } catch (error) {
    console.error('Error processing files:', error);
    const retry = window.confirm('Error processing files. Would you like to retry?');
    if (retry) {
      handleConfirm();
    } else {
      setCurrentStep(3);
    }
  }
};


  // In FileWizard.jsx, update the handleReview function:

  const handleReview = (file) => {
    console.log('Review requested for file:', file);
  
    if (!file || !file.baseName || !file.video) {
      console.error('Invalid file passed to handleReview:', file);
      alert('Cannot review: Invalid file structure.');
      return;
    }
  
    if (file.video?.file && file.transcript) {
      fileSystemManager.cleanup();
      const fileData = {
        ...file.video,
        url: URL.createObjectURL(file.video.file),
      };
  
      fileSystemManager.addFilePair(fileData, file.transcript);
    }
  
    const filePair = fileSystemManager.getFilePair(file.baseName);
    console.log('Found file pair:', filePair);
  
    if (filePair?.video && filePair?.transcript?.transcription) {
      setCurrentVideo({
        name: filePair.video.name,
        url: filePair.video.url,
        size: filePair.video.size,
        type: filePair.video.type,
        transcription: filePair.transcript.transcription,
      });
    } else {
      alert('Cannot review: Missing required files');
    }
  };
  

  const handleCloseVideo = () => {
    setCurrentVideo(null);
  };

  const renderFileList = () => {
    if (syncError) {
      return (
        <div className="p-4 bg-red-50 rounded-lg mb-4">
          <div className="flex items-start space-x-3">
            <AlertCircle className="w-6 h-6 text-red-500 mt-1" />
            <div>
              <h3 className="font-medium text-red-800">Error Syncing Folder</h3>
              <p className="text-red-700 mt-1">{syncError}</p>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Your Files</h2>
          <div className="flex space-x-4">
            <button
              onClick={() => setCurrentStep(2)}
              className="flex items-center px-4 py-2 bg-[#2d3b31] text-[#d1dbd3] rounded-lg hover:bg-[#3d4b41] transition-colors"
            >
              <Plus className="w-4 h-4 mr-2" />
              New Files
            </button>
            <button
              onClick={() => {
                const input = document.createElement('input');
                input.type = 'file';
                input.webkitdirectory = true;
                input.multiple = true;
                input.onchange = handleSyncFolder;
                input.click();
              }}
              className="flex items-center px-4 py-2 bg-[#b3c2b7] text-white rounded-lg hover:bg-[#95a499] transition-colors"
            >
              <FolderOpen className="w-4 h-4 mr-2" />
              Load Files
            </button>
          </div>
        </div>
  
        {syncedFiles.length === 0 ? (
          <div className="text-center py-12">
            <FolderOpen className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-1">No files synced</h3>
            <p className="text-gray-500">Select a folder to sync your video and transcript files.</p>
          </div>
        ) : (
          <div className="border rounded-lg divide-y">
            {syncedFiles.map((file) => (
              <FileItem
                key={file.baseName}
                baseName={file.baseName}
                video={file.video}
                transcript={file.transcript}
                onReview={() => handleReview(file)}
                onExport={() => handleExport(file.baseName)}
              />
            ))}
          </div>
        )}
      </div>
    );
  };
  
  

  const renderContent = () => {
    if (currentVideo) {
      return (
        <VideoPlayerComponent
          videoUrl={currentVideo.url}
          transcription={currentVideo.transcription}
          onClose={handleCloseVideo}
        />
      );
    }

    const showServerError = serverStatus.checked && 
                          !serverStatus.isRunning && 
                          currentStep > 1 && 
                          process.env.NODE_ENV === 'production';

    if (showServerError) {
      return (
        <div className="max-w-xl mx-auto">
          <div className="p-4 bg-yellow-100 text-yellow-700 rounded-lg mb-4">
            Warning: Cannot connect to server at {API_CONFIG.BASE_URL}. 
            Files can be selected but won't be processed.
          </div>
          {renderCurrentStep()}
        </div>
      );
    }

    return renderCurrentStep();
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <WelcomeStep onStart={() => setCurrentStep(2)} onReview={() => setCurrentStep(5)} />;
      case 2:
        return <SelectFolderStep onSelect={handleFileSelect} />;
      case 3:
        return (
          <ConfirmStep 
            files={selectedFiles} 
            onConfirm={handleConfirm}
            onCancel={() => setCurrentStep(2)}
          />
        );
      case 4:
        return (
          <ProcessingStep 
            files={selectedFiles}
            processingStatus={processingStatus}
          />
        );
      case 5:
        return renderFileList();
      default:
        return null;
    }
  };

  const handleHeaderClick = () => {
    setSelectedFiles([]);
    setCurrentVideo(null); // Reset the video view
  };

  return (
    <WizardLayout
      currentStep={currentVideo ? null : currentStep}
      title={getDisplayTitle()}
      setCurrentStep={setCurrentStep}
      handleHeaderClick={handleHeaderClick}
      onLogout={onLogout} // Pass down the logout function
      setCurrentPage={setCurrentPage}
    >
      {renderContent()}
    </WizardLayout>
  );
}