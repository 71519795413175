import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;
  } catch (err) {
    console.error("Error decoding token:", err);
    return true; // Treat invalid token as expired
  }
};


export const authFetch = async (url, options = {}) => {
  const token = localStorage.getItem('claraJwtToken');

  if (!token || isTokenExpired(token)) {
    localStorage.removeItem("jwtToken");
    window.location.href = "/login"; // Redirect to login
    return Promise.reject("Session expired");
  }

  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(url, {
    ...options,
    headers,
  });

  if (response.status === 401) {
    // Token expired or invalid, clear localStorage and reload
    localStorage.removeItem('claraJwtToken');
    window.location.reload();
    throw new Error('Unauthorized: Token expired or invalid');
  }

  return response;
};
