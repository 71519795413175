import React from "react";

const SessionExpiredModal = ({ isOpen, onRelogin }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-sm text-center">
        {/* Modal Header */}
        <h2 className="text-2xl font-serif text-[#2d3b31] mb-4">Session Expired</h2>
        <p className="text-gray-600 mb-6">
          Your session has expired. Please log in again to continue.
        </p>
        {/* Modal Buttons */}
        <button
          onClick={onRelogin}
          className="w-full px-6 py-3 bg-[#2d3b31] text-[#d1dbd3] rounded-md hover:bg-[#3d4b41] transition-colors duration-200 font-medium"
        >
          Log In Again
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
