import React, { useState } from 'react';
import { API_CONFIG } from '../api/claraService';

const LoginScreen = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agreedToTOS, setAgreedToTOS] = useState(false); // Track agreement to TOS

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_CONFIG.BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid username or password');
      }

      const data = await response.json();
      localStorage.setItem('claraJwtToken', data.token);
      onLoginSuccess();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#d1dbd3]">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        {/* Logo and Title */}
        <div className="flex items-center justify-center mb-6">
          <img
            src="/clara-icon.png"
            alt="Clara Logo"
            className="w-24 h-24 mr-4"
          />
          <h1 className="text-3xl font-serif text-center text-[#2d3b31]">
            Clara AI Login
          </h1>
        </div>
        {/* Login Form */}
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="username" className="block text-gray-700 mb-2">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#b3c2b7]"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-700 mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#b3c2b7]"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              id="agreeToTOS"
              checked={agreedToTOS}
              onChange={(e) => setAgreedToTOS(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="agreeToTOS" className="text-sm text-gray-700">
              I agree to the{' '}
              <a
                href="/tos"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-700"
              >
                Terms of Service
              </a>
            </label>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            disabled={!agreedToTOS || loading}
            className={`w-full py-2 rounded-lg transition-colors ${
              agreedToTOS
                ? 'bg-[#2d3b31] text-white hover:bg-[#3d4b41]'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
