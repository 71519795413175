import React from 'react';
import { FileIcon } from 'lucide-react';



const Header = ({ onHome, onMyFiles, onLogout }) => (
  <header className="bg-[#d1dbd3] shadow-md">
    <div className="max-w-7xl mx-auto px-6 py-4 flex justify-between items-center">
      {/* Left side: Logo and title */}
      <div className="flex items-center space-x-4 cursor-pointer" onClick={onHome}>
        <div className="w-12 h-12 relative">
          <div className="absolute inset-0 rounded-lg flex items-center justify-center">
            <img 
              src="/clara-icon.png" 
              alt="Clara AI" 
              className="w-12 h-12 object-contain rounded-lg"
            />
          </div>
        </div>
        <h1 className="text-2xl text-gray-800 font-serif tracking-wide">Clara AI</h1>
      </div>

      {/* Right side: Navigation links and Logout button */}
      <nav className="flex space-x-6 items-center">
        <button onClick={onHome} className="text-gray-700 hover:text-gray-900 transition-colors">Home</button>
        <button onClick={onMyFiles} className="text-gray-700 hover:text-gray-900 transition-colors">My Files</button>
        {/* <button className="text-gray-700 hover:text-gray-900 transition-colors">Contact Support</button> */}
        <button 
          onClick={onLogout}
          className="px-4 py-2 bg-[#2d3b31] text-[#d1dbd3] rounded-lg hover:bg-[#3d4b41] transition-colors"
        >
          Log Out
        </button>
      </nav>
    </div>
  </header>
);

const Footer = () => (
  <footer className="py-4 px-6 mt-auto">
    <div className="max-w-7xl mx-auto text-center text-gray-300">
      <p>
        Copyright © CASCDR Inc {new Date().getFullYear()}{' '}
        <br></br>
        {' '}
        <a
          href="/tos"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-gray-200 transition-colors"
        >
          Terms of Service
        </a>{' '}
        |{' '}
        <a
          href="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-gray-200 transition-colors"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  </footer>
);



const handleHomeClick = (setCurrentStep, handleHeaderClick) => {
  setCurrentStep(1);
  handleHeaderClick();
}

const handleMyFilesClick = (setCurrentStep, handleHeaderClick) => {
  setCurrentStep(5);
  handleHeaderClick();
}


const WizardLayout = ({ children, currentStep, setCurrentStep, title, handleHeaderClick, onLogout, setCurrentPage }) => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Header
        onHome={() => handleHomeClick(setCurrentStep, handleHeaderClick)}
        onMyFiles={() => handleMyFilesClick(setCurrentStep, handleHeaderClick)}
        onLogout={onLogout}
      />
      <main className="flex-1 max-w-4xl mx-auto w-full px-6 py-12">
        {title && (
          <h2 className="text-4xl font-bold mb-2">
            {title}
          </h2>
        )}
        {children}
      </main>
      <Footer onViewTOS={() => setCurrentPage('tos') } onViewPP={() => setCurrentPage('privacy')} />
    </div>
  );
};


  const WelcomeStep = ({ onStart, onReview }) => (
    <div className="flex flex-col items-center justify-center py-16">
      <div className="w-64 h-64 mb-8 relative">
        <div className="absolute inset-0 rounded-xl  flex items-center justify-center bg-transparent">
          <img 
            src="/clara-icon.png" 
            alt="Clara AI" 
            className="w-64 h-64 object-contain rounded-lg"
          />
        </div>
      </div>
      <h2 className="text-5xl font-serif text-gray-800 mb-2">Clara AI</h2>
      <h4 className="text-xl font-serif text-gray-600 mb-6">Creating Transparency in Pursuit of Justice for All</h4>
      <button
        onClick={onStart}
        className="px-8 py-3 bg-[#b3c2b7] mb-4 rounded-lg hover:bg-[#d1dbd3] transition-colors duration-200 text-lg font-medium shadow-sm"
      >
        Import New Footage
      </button>
      <button
        onClick={onReview}
        className="px-8 py-3 mb-4 bg-[#2d3b31] text-[#d1dbd3] rounded-lg hover:bg-[#3d4b41] transition-colors duration-200 text-lg font-medium shadow-sm"
      >
        Review Existing
      </button>
    </div>
  );
  
  const FileItem = ({ 
  name, 
  size, 
  showReview = true, 
  onReview, 
  onExport, 
  lastProcessed, 
  isConfirmStep = false,
  // For synced files
  baseName,
  video,
  transcript
}) => {
  // For confirmation step, show simpler version
  if (isConfirmStep) {
    return (
      <div className="border-b last:border-b-0 p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <FileIcon className="w-5 h-5 text-gray-400" />
            <div>
              <div className="font-medium text-gray-700">{name}</div>
              {size && (
                <div className="text-sm text-gray-500">
                  {typeof size === 'number' ? `${Math.round(size / 1024 / 1024)}MB` : size}
                </div>
              )}
            </div>
          </div>
          {showReview && (
            <div className="flex space-x-2">
              <button
                className="px-3 py-1 text-sm bg-gray-100 text-gray-400 rounded cursor-not-allowed"
                disabled={true}
              >
                Review
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  // For processed/synced files
  const hasRequiredFiles = Boolean(video && transcript?.transcription);
  const lastModified = video?.lastModified || transcript?.lastModified 
    ? new Date(Math.max(
        video?.lastModified || 0,
        transcript?.lastModified || 0
      )).toLocaleString()
    : lastProcessed;

  return (
    <div className="border-b last:border-b-0 p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <FileIcon className="w-5 h-5 text-gray-400" />
          <div>
            <div className="font-medium text-gray-700">
              {name || video?.name || baseName}
            </div>
            <div className="text-sm text-gray-500">
              {video 
                ? `${Math.round(video.size / 1024 / 1024)}MB`
                : size 
                  ? `${Math.round(size / 1024 / 1024)}MB` 
                  : 'Video file missing'}
              {lastModified && ` • Last modified: ${lastModified}`}
            </div>
            {!hasRequiredFiles && !isConfirmStep && (
              <div className="text-sm text-amber-600 mt-1">
                {!video && !transcript 
                  ? 'Missing video and transcript files'
                  : !video 
                    ? 'Missing video file'
                    : !transcript 
                      ? 'Missing transcript file'
                      : !transcript.transcription 
                        ? 'Invalid transcript file'
                        : 'Files incomplete'}
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          {transcript?.transcription && (
            <button
              onClick={onExport}
              className="px-4 py-1.5 bg-[#2d3b31] text-[#d1dbd3] rounded-md hover:bg-[#3d4b41] transition-colors duration-200 text-sm font-medium transition-colors duration-200"
            >
              Export
            </button>
          )}
          {showReview && (
            <button
              onClick={onReview}
              className={`px-4 py-1.5 text-sm rounded transition-colors duration-200 ${
                hasRequiredFiles 
                  ? 'bg-[#d1dbd3] text-[#2d3b31] rounded-md hover:bg-[#b3c2b7] transition-colors duration-200 text-sm font-medium'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
              disabled={!hasRequiredFiles}
            >
              Review
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export { WizardLayout, WelcomeStep, FileItem };