import React from 'react';

const PrivacyPolicy = () => (
  <main className="p-6 max-w-4xl mx-auto">
    <section>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        <strong>Effective Date:</strong> 12/5/2024
      </p>

      <p className="mb-6">
        Welcome to Clara AI created by CASCDR Inc. We value your privacy and are committed to protecting your data. This Privacy Policy explains how we collect, use, and protect your information when using our service.
      </p>

      <h2 className="text-2xl font-semibold mb-3">1. Data Collection and Use</h2>
      <p className="mb-6">
        Clara AI processes sensitive bodycam footage for transcription and translation purposes. All data is processed solely to provide the agreed-upon service and is not used for any other purpose.
      </p>

      <h2 className="text-2xl font-semibold mb-3">2. Data Storage and Retention</h2>
      <p className="mb-6">
        Clara AI takes precautions to ensure that all sensitive data remains in the client at rest and is not stored at rest within our systems. We do not retain user data beyond what is necessary to fulfill service obligations.
      </p>

      <h2 className="text-2xl font-semibold mb-3">3. Security Measures</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>We adhere to standard security practices to safeguard your data.</li>
        <li>Our systems utilize third-party SOC2-compliant tools to enhance data security.</li>
        <li>Despite our rigorous efforts, we cannot guarantee the prevention of unauthorized access or data leaks.</li>
        <li>We do not engage in logging of sensitive data and access diagnostics only upon specific request.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">4. Third-Party Services</h2>
      <p className="mb-6">
        Clara AI may integrate with third-party tools and platforms that meet SOC2 compliance standards. These services have their own privacy policies, which we encourage you to review. We are not liable for any issues arising from third-party services.
      </p>

      <h2 className="text-2xl font-semibold mb-3">5. User Responsibility</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>Users are responsible for ensuring the confidentiality of their data and reporting any suspected breaches promptly.</li>
        <li>We recommend reviewing all outputs for accuracy and completeness before using them in any official capacity.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">6. Liability Disclaimer</h2>
      <p className="mb-6">
        Clara AI, CASCDR Inc, and its affiliates shall not be held liable for any incidental, indirect, or consequential damages resulting from accidental data leaks or breaches beyond our reasonable control.
      </p>

      <h2 className="text-2xl font-semibold mb-3">7. Modifications to Privacy Policy</h2>
      <p className="mb-6">
        We reserve the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting. Continued use of the service constitutes acceptance of the revised policy.
      </p>

      <h2 className="text-2xl font-semibold mb-3">8. Contact Information</h2>
      <p className="mb-6">
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p className="mb-6 font-semibold">luke@cascdr.xyz</p>

      <p className="mb-6">
        By using this service, you acknowledge that you have read, understood, and agree to this Privacy Policy.
      </p>
    </section>
  </main>
);

export default PrivacyPolicy;
